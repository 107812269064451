import Breadcrumbs from '@components/common/Breadcrumbs';
import CustomLink from '@components/common/CustomLink';
import Grid from '@components/common/Grid';
import OrganisedDetails from '@components/OrganisedDetails';
import React from 'react';
import SEO from '@components/common/SEO';
import { graphql } from 'gatsby';
import { labels } from '@src/constants';
import { renderBlocks } from '@src/helpers';

function Post({ data, pageContext }) {
  const { language: lang, id } = pageContext;
  const {
    seo,
    date,
    title,
    ACFArticolo,
    ACFArticolo: { credits, content },
  } = data.wpArticle;

  // fetch taxonomies
  // set up breadcrumbs

  return (
    <>
      <SEO seo={seo} language={lang} />
      <Grid animKey={`page-${id}`} transition>
        <Grid className="col-span-12 bg-white py-12 lg:sticky lg:top-48 lg:z-9">
          <OrganisedDetails className="sm:col-span-6 lg:col-span-3">
            <p>
              <span className="block">{labels.title[lang]}:</span>
              <span className="block">{title}</span>
            </p>
            {/* <Breadcrumbs current={title} parent="journal" /> */}
          </OrganisedDetails>
          <OrganisedDetails className="sm:col-span-6 lg:col-span-3">
            <p>
              <span className="block">{labels.date[lang]}:</span>
              <span className="block">{date}</span>
            </p>
            {/* <p className="flex flex-wrap justify-start items-baseline">
            <span className="flex-20 min-w-130 max-w-200">{labels.category[lang]}</span>
            <span className="flex-2-auto">Categoria 1</span>
          </p> */}
          </OrganisedDetails>
          {credits && (
            <OrganisedDetails className="sm:col-span-6 lg:col-span-3">
              <p>
                <span className="block">{credits[0].title}:</span>
                <span className="block">
                  {credits[0].link ? (
                    <CustomLink url={credits[0].link} target="_blank">
                      {credits[0].name}
                    </CustomLink>
                  ) : (
                    <>{credits[0].name}</>
                  )}
                </span>
              </p>
            </OrganisedDetails>
          )}
          {credits && (
            <OrganisedDetails className="sm:col-span-6 lg:col-span-3">
              <p>
                <span className="block">{labels.multimedia[lang]}:</span>
                {credits.map((credit, i) => {
                  if (i === 0) return null;

                  return (
                    <React.Fragment key={i}>
                      {credit.link ? (
                        <>
                          <CustomLink url={credit.link} target="_blank">
                            {credit.name}
                          </CustomLink>
                          {i < credits.length - 1 && <>, </>}
                        </>
                      ) : (
                        <>
                          {credit.name}
                          {i < credits.length - 1 && <>, </>}
                        </>
                      )}
                    </React.Fragment>
                  );
                })}
              </p>
            </OrganisedDetails>
          )}
        </Grid>
        <h1 className="text-md sm:text-lg col-span-12 tracking-headline">{title}</h1>
        {renderBlocks(content)}
      </Grid>
    </>
  );
}

export const data = graphql`
  query Post($id: String) {
    wpArticle(id: { eq: $id }) {
      title
      date(formatString: "DD.MM.YYYY")
      ACFArticolo {
        credits {
          link
          name
          title
        }
        content {
          ... on WpArticle_Acfarticolo_Content_Blockquote {
            copy
            fieldGroupName
            source
          }
          ... on WpArticle_Acfarticolo_Content_Copy {
            align
            bodyType
            title
            fieldGroupName
            copy
            columns
            paddingBottom
          }
          ... on WpArticle_Acfarticolo_Content_PostSubtitle {
            align
            columns
            fieldGroupName
            subtitle
          }
          ... on WpArticle_Acfarticolo_Content_MediaGrid {
            columns
            borderBottom
            fieldGroupName
            media {
              embedSource
              mediaType
              source
              linkSource {
                sourceUrl
              }
              image {
                altText
                caption
                mediaDetails {
                  width
                  height
                  sizes {
                    height
                    name
                    sourceUrl
                    width
                  }
                }
                mediaItemUrl
              }
            }
          }
          ... on WpArticle_Acfarticolo_Content_Video {
            autoplay
            borderBottom
            source
            mute
            loop
            hideControls
            fieldGroupName
            embedSource
            linkSource {
              sourceUrl
            }
          }
          ... on WpArticle_Acfarticolo_Content_Image {
            borderBottom
            fieldGroupName
            image {
              altText
              caption
              mediaDetails {
                width
                height
                sizes {
                  height
                  name
                  sourceUrl
                  width
                }
              }
              mediaItemUrl
            }
          }
        }
      }
      seo {
        ...seoFields
      }
    }
  }
`;

Post.displayName = 'Post';

export default Post;
